import * as React from "react";

import { Icon, IconProps } from "@tabler/icons-react";
import { cn } from "~/lib/utils";
import { Label } from "./label";

interface BaseInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  label?: string;
  icon?: React.ForwardRefExoticComponent<
    Omit<IconProps, "ref"> & React.RefAttributes<Icon>
  >;
}

const BaseInput = React.forwardRef<HTMLInputElement, BaseInputProps>(
  ({ className, type, error, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-7 w-full rounded-md border bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-0 focus-visible:border-primary-foreground disabled:cursor-not-allowed disabled:opacity-50",
          error ? "border-destructive-foreground" : "border-input",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, label, error, icon: IconComponent, ...props }, ref) => {
    return (
      <div className={cn("grid items-center gap-1.5")}>
        {label && <Label>{label}</Label>}
        {IconComponent ? (
          <div
            className={cn(
              "flex justify-start items-center relative w-full ",
              className
            )}
          >
            <IconComponent className="absolute ml-2 w-4 h-4" />
            <BaseInput
              className={cn("pl-8 w-full", className)}
              error={error}
              {...props}
              ref={ref}
              type={type}
            ></BaseInput>
          </div>
        ) : (
          <BaseInput
            {...props}
            className={className}
            type={type}
            ref={ref}
            error={error}
          />
        )}
        {error && (
          <Label className="ml-2">
            <span className="text-destructive-foreground">{error}</span>
          </Label>
        )}
      </div>
    );
  }
);
BaseInput.displayName = "InputBase";

interface InputProps extends BaseInputProps {
  icon?: React.ForwardRefExoticComponent<
    Omit<IconProps, "ref"> & React.RefAttributes<Icon>
  >;
}

Input.displayName = "Input";

export { BaseInput, Input };
